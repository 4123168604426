<template>
  <div>
    <loading :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false">
    </loading>
    <strong v-if="service == null">No hay servicios programados</strong>
    <b-col lg="12" v-else>
      <strong>Próximo servicio</strong>
      <CCard
        class="mb-0"
        :style="`margin-top:0.5rem`">
        <CCardHeader >
          <b-row>
            <b-col lg="6">
              <h5>{{service.obs_cliente}}</h5>
            </b-col>
            <b-col lg="6" >
              <CIcon 
                name="cilCircle" 
                size="md" 
                height="48" 
                alt="Logo" 
                :style="colorHeader(service.estado_servicio.id)"/>                  
              {{service.estado_servicio.nombre}}
            </b-col>
            <b-col lg="6">
              <strong>Fecha:</strong> {{service.fecha_inicio}}
            </b-col>
            <b-col lg="6">
              <strong>Paseador:</strong> {{service.usuario != null ? service.usuario.fullname : ''}}
            </b-col>                
            <b-col lg="6">
              <CIcon name="cilDog" size="md" height="48" alt="Logo" />
              {{service.mascota.nombre}}
            </b-col>                                            
          </b-row>   
          <br>
          <b-row>                            
            <b-col lg="4" sm="6" v-if="service.estado_servicio.id == 2">                  
              <b-button 
                class="mr-1"
                size="sm"
                style="width:100%;"
                @click="openMap(service)"  >                    
                Ver mapa <CIcon name="cilMap" size="sm" height="48" alt="Logo" />
              </b-button> 
            </b-col>  
          </b-row>                                                                                                                                              
        </CCardHeader>
                                              
      </CCard>
    </b-col>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import moment from 'moment';
  export default {
    name: "schedule",    
    props: ['userDbId'],
    components: {Loading},
    data(){
      return {
        service: null,
        loading: true,
      }
    },
    created(){      
      this.getNextService();
    },
    methods: {
      getNextService(){
        let fetchServiceScheduled = this.$firebase.functions().httpsCallable('fetchNextService');
        let payload = {
          userDbId: this.userDbId
        }
        fetchServiceScheduled(payload).then((response) => {          
          let data = response.data.data;          
          if(data.length > 0)
            this.service = data[0];  
          console.log(this.service)                              
        }).catch((error) => {
          console.log(error)
        }).finally(_=>this.loading=false);
      },
      colorHeader (state) {      
        switch (state) {
          case 1:
            return {color: '#f9b115'};
            break;
          case 2:
            return {color: '#2eb85c'};
            break;
          case 34:          
            return {color: '#e55353'};
            break;
        
          default:
            return {color: '#39f'};//blue
            break;
        }
      },
      openMap(item, index, button)  {
        this.loading = true;        
        let payload = {
          serviceId: item.id
        }
        let getContextUrlTracking = this.$firebase.functions().httpsCallable('getUrlTracking')
        getContextUrlTracking(payload).then((result) => {
          const contextUrl = result.data.data                          
          this.loading=false;
          this.$router.push({path: '/services/gps?' + contextUrl});
        }).catch((error) => {
          console.log(error)
        })
      },
    }
      
  }
</script>

<style scoped>

</style>