<template>
    <div>
        <loading :active.sync="loading"
            :can-cancel="false"
            background-color="#fff"
            :is-full-page="false">
        </loading>
        <div v-if="hasUserProfile">
          <h2>¡Hola {{userName}}!</h2>
          <TermsAcceptance              
            v-if="
              (userProfile != null)  && 
              (userProfile.flag_terminos == null || 
              userProfile.flag_terminos.id == 2)"
              
            :userProfile="userProfile"/>
          <b-row>
            <b-col lg="6">   
              <Menu/>
            </b-col> 
            <b-col lg="6" v-if="userDbId != null">   
              <ScheduleReview :userDbId="userDbId"/>
            </b-col> 
          </b-row>
          <!-- <b-row>
            <b-col sm="6" lg="3">   -->
              <!-- <CWidgetIcon
                :header="petsLength"
                text="Mascotas"
                color="gradient-primary"
                >
                <CIcon name="cil-animal" width="24"/>
                <template #footer>
                  <CCardFooter class="px-3 py-2">
                    <CLink
                        class="font-weight-bold font-xs text-muted d-flex justify-content-between"                        
                        target="_blank"
                        @click="goTo('petsIndex')"
                    >
                        Abrir detalle
                        <CIcon name="cil-arrow-right" width="16"/>
                    </CLink>
                  </CCardFooter>
                </template>
              </CWidgetIcon>            
            </b-col>
            
            <b-col sm="6" lg="3">
              <CWidgetIcon
                :header="tagsLength"
                text="Placas QR"
                color="gradient-info"
                >
                <CIcon name="cil-star" width="24"/>
                <template #footer>
                  <CCardFooter class="px-3 py-2">
                    <CLink
                        class="font-weight-bold font-xs text-muted d-flex justify-content-between"                        
                        target="_blank"
                        @click="goTo('buyTag')"
                    >
                        Comprar
                        <CIcon name="cil-arrow-right" width="16"/>
                    </CLink>
                  </CCardFooter>
                </template>
              </CWidgetIcon> -->
            <!-- </b-col>                     
          </b-row>         -->
        </div>
        <div v-else>
            <b-row>
                <b-col md="5">
                <h4 class="display-4">¡Bienvenido!</h4>
                <p class="lead">{{ $t('phrases.msg_intro') }}</p>
                <p class="">{{ $t('phrases.msg_intro_b') }}</p>               
                <hr clas="my-4"/>
                 <b-button 
                    class="mr-1 s-bg s-bg-text"
                    style="width:100%; margin-bottom:1rem;"
                    @click.prevent="visitCreateProfile"
                    >
                    <strong>Crea tu perfil ahora</strong>      
                </b-button> 
                
                </b-col>
                <b-col md="7">
                  <div>                                       
                    <b-card
                      overlay
                      :img-src="serviceImage"
                      img-alt="Card Image"
                      text-variant="black"
                      title="Go Guau..."
                      >
                        Tu mejor amigo en manos amigas.
                    </b-card>                    
                  </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import TermsAcceptance from '../modals/TermsAcceptance';
import Menu from './Menu';
import ScheduleReview from './ScheduleReview'
export default {
  name: 'DashboardUser',
  components: {Loading, TermsAcceptance, Menu, ScheduleReview},
  data () {
    return {
      loading: true,
      userProfile: null,
      userName: null,
      userCurrency: null,
      hasUserProfile: false,
      userId: null,
      userDbId: null,
      serviceImage: 'https://storage.googleapis.com/test-goguau.appspot.com/resources/paseo2-min.jpeg',
      // userProfileInfo
      petsLength: 0+'',
      tagsLength: 0+'',        
    }
  },  
  created () {
      // this.loading = false;
      this.userId = this.$store.getters.userId;
      let userProfile = JSON.parse(this.$store.getters.userProfile);                       
      
      if (userProfile != null){      
        this.userDbId = userProfile.id  
        if(userProfile.type == 'provider')
          this.$router.push({name: 'provider-dashboard'})
        this.userProfile = userProfile        
        this.userName = userProfile.nombre.split(' ',1)[0]       
        this.fetchProfileInfo()
        this.hasUserProfile = true      
      }          
      this.loading= false       
  },
  methods: {
    visitCreateProfile: function(){
      this.$router.replace('/users/create')
    },
    fetchProfileInfo () {        
      // console.log(this.userProfile)       
      if('pets' in this.userProfile){
        let pets = this.userProfile.pets
        this.petsLength = pets.length+''

        const tags = pets.filter(pet => pet.tag == 'Si')
        this.tagsLength = tags.length + ''
                  
      }
    },
    goTo(to) {
      if (to == 'buyTag') {
        this.$router.replace({path: '/services/profile/3'})        
      } else if (to == 'petsIndex') {
        this.$router.replace({path: '/pets/index'})
      }
    }
  }
}
</script>

<style scoped>

</style> 