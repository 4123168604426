<template>
  <div>   
    <b-row> 
      <b-col lg="6" md="6">        
        <CWidgetIcon
          header="Cupones de descuentos"
          text="Ahorra en tiendas aliadas"
          color="gradient-primary">
          <CIcon name="cil-tags" width="24"/>
          <template #footer>
            <CCardFooter class="px-3 py-2">
              <CLink
                class="font-weight-bold font-xs text-muted d-flex justify-content-between"                        
                target="_blank"
                @click="goTo('coupons-dashboard')">
                  Abrir
                <CIcon name="cil-arrow-right" width="16"/>
              </CLink>
            </CCardFooter>
          </template>
        </CWidgetIcon>         
      </b-col>   

      <b-col lg="6" md="6">               
        <CWidgetIcon
          header="Servicios y Productos y más"
          text="Collares antitiores, paseos, placas QR ..."
          color="gradient-info">
          <CIcon name="cil-list" width="24"/>
          <template #footer>
            <CCardFooter class="px-3 py-2">
              <CLink
                class="font-weight-bold font-xs text-muted d-flex justify-content-between"                        
                target="_blank"
                @click="goTo('services-index')">
                  Abrir
                <CIcon name="cil-arrow-right" width="16"/>
              </CLink>
            </CCardFooter>
          </template>
        </CWidgetIcon> 
      </b-col>   

    </b-row>
  </div>
</template>

<script>
  export default {
    name: "menu",
    props: ['userProfile'],
    data(){
      return {

      }
    },
    methods: {
      goTo(to){
        if (to == 'coupons-dashboard') {
          this.$router.push({name: to})        
        } else if (to == 'services-index') {
          this.$router.push({name: to})
        }
      },
    }
      
  }
</script>

<style scoped>

</style>